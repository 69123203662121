import { fetchBaseQuery, retry } from "@reduxjs/toolkit/dist/query";
import { AppConfigs } from "config/appConfigs";
import { businessGroupDetails } from "consts/globals";
import { RootState } from "store";
import { AuthClientProvider } from "./client";

export function baseQuery() {
  return fetchBaseQuery({
    baseUrl: AppConfigs().templateServiceBaseUrl,
    prepareHeaders: async (headers: Headers, { getState }) => {
      const authClient = AuthClientProvider.getInstance();

      // Find the templateService scope from the array of key-value pairs
      const templateServiceScope =
        AppConfigs().authentication.scopeTemplateService;

      if (!templateServiceScope) {
        throw new Error("Template service scope not found");
      }
      const authenticationToken = await authClient.getAuthenticationToken(
        templateServiceScope
      );

      if (AppConfigs().businessUnitFeatureFlagging === true) {
        // Does this call needs to be run every time
        const { businessUnit } = getState() as RootState;
        const usrBusinessGroup = businessGroupDetails.find(
          (b) => b.tenant === businessUnit
        )?.businessGroup;

        headers.set("Tenant", `${usrBusinessGroup}`);
      }

      headers.set("authorization", `bearer ${authenticationToken}`);
      return headers;
    },
  });
}

export const baseQueryWithRetry = retry(baseQuery());
