import axios from "axios";
import { AppConfigs } from "config/appConfigs";
import { useCallback } from "react";
import { AuthClientProvider } from "services/client";

const useDocumentPreview = () => {
  const generateIFramePreview = useCallback(
    async (
      fileName: string
    ): Promise<{ isError: boolean; content: string }> => {
      try {
        const authClient = AuthClientProvider.getInstance();

        // Find the documentService scope from the array of key-value pairs
        const documentServiceScope =
          AppConfigs().authentication.scopeDocumentService;

        if (!documentServiceScope) {
          throw new Error("Document service scope not found");
        }
        const authenticationToken = await authClient.getAuthenticationToken(
          documentServiceScope
        );
        if (!authenticationToken) {
          throw new Error("Failed to retrieve authentication token");
        }
        const headers = {
          headers: { authorization: `bearer ${authenticationToken}` },
        };
        const url = `${
          AppConfigs().wopiHostBaseUrl
        }/officehost/templates/files/${fileName}?wopi_action=embedview`;
        const response = await axios.get<string>(url, headers);
        const isError = response.status !== 200;
        return {
          isError,
          content: isError ? `status code: ${response.status}` : response.data,
        };
      } catch {
        // Request failed to send
        return { isError: true, content: "error sending request" };
      }
    },
    []
  );

  return { generateIFramePreview };
};

export default useDocumentPreview;
